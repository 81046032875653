/*
 * Header
 */
 .masthead {
    margin-bottom: 2rem;
  }

  .img-logo{
    width: 100px;
    height: 100px;
  }
  
  .masthead-brand {
    margin-bottom: 0;
  }
  
  .nav-masthead .nav-link {
    padding: .25rem 0;
    font-weight: 700;
    color: rgba(255, 255, 255, .5);
    background-color: transparent;
    border-bottom: .25rem solid transparent;
  }
  
  .nav-masthead .nav-link:hover,
  .nav-masthead .nav-link:focus {
    border-bottom-color: rgba(255, 255, 255, .25);
  }
  
  .nav-masthead .nav-link + .nav-link {
    margin-left: 1rem;
  }
  
  .nav-masthead .active {
    color: #fff;
    border-bottom-color: #fff;
  }

  @media (max-width: 480px) {
      .nav-masthead {
        text-align: end;
      }

  }
  
  @media (min-width: 48em) {
    .masthead-brand {
      float: left;
    }
    .nav-masthead {
      text-align: end;
    }
  }

  @media only screen and (max-width: 480px) {
    h1{
      font-size: 1.5rem;
    }
    .nav-masthead .nav-link{
        font-weight: 500 !important;
        font-size: 0.85rem;
        color: #FFF !important;
    }
    .navbar-collapse{
      margin-top: 5px;
      background-color: rgba(126, 182, 214, 0.2) !important;
    }
  }
  @media only screen and (max-width: 320px) {
    h1{
      font-size: 1.0rem;
    }
    .lead{
      font-size: 0.90rem;
    }
    .nav-masthead .nav-link{
      font-weight: 10 !important;
      font-size: 0.65rem;
    }
    .masthead-brand{
      font-size: 1.25rem !important;
    }
  }
  