.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: .05rem solid #fff;
}


/*
 * Base structure
 */

html,
body {
  height: 100%;
  background-color: #000;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: #fff;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.bg {
  background-image: url("./images/brooklean.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFF;
  }

/*
 * Cover
 */

.bg-info{
  /* opacity: 0.7;
  filter: alpha(opacity=60); For IE8 and earlier */
  background-color: rgba(126, 182, 214, 0.4) !important;
  margin-top: 40px;
}

.cardImg{
    width: 100%;
    height: 10vw;
    object-fit: cover;
    margin-bottom: 15px;
}

div.bg-info p{
  color: #FFF;
  padding: 10px;
}
.cover {
  padding: 0 1.5rem;
}
.cover .btn-lg {
  padding: .75rem 1.25rem;
  font-weight: 700;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(253, 245, 245, 0.1);
}

.jumbotron{
  color: #000;
}

.cardBg{
  background-color: #FFF;
  text-align: left;
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;
  width: 100%;
}


/*
 * Footer
 */
.mastfoot {
  color: rgba(255, 255, 255, .5);
}


.org-logo-jj{
  width: 300px;
  height: 80px;
  padding: 10px;
}

.org-logo-equinix{
  width: 150px;
  height: 80px;
  padding: 10px;
}

.org-logo-sph{
  width: 100px;
  height: 100px;
  padding: 10px;
}

.org-logo-lnt{
  width: 300px;
  height: 100px;
  padding: 10px;
}

.org-logo-absa{
  width: 150px;
  height: 100px;
  padding: 10px;
}

.org-logo-nic{
  width: 200px;
  height: 100px;
  padding: 10px;
}
.org-info{
  background-color: rgba(136, 152, 168, 0.87) !important;
}
