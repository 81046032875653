@media only screen and (min-width: 1025px){
.ml-6{
    margin-left: 7rem !important;
}

.mr-6{
    margin-right: 7rem !important;
}
}

